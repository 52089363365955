import React from 'react';
import {graphql} from 'gatsby';
import PropTypes from 'prop-types';

import Hero from 'components/Hero.js';
import Layout from 'components/Layout.js';
import Meta from 'components/Meta.js';
import Tiles from 'components/Tiles.js';


export default class SolutionPage extends React.Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
    };

    render() {
        const content = this.props.data.wordpressWpSolutions;
        return (
            <Layout className="contain" solution={content.slug}>
                <Meta
                    description={content.acf.subtitle}
                    title={content.title}
                />
                <Hero
                    solution={content.link}
                    subtitle={content.acf.subtitle}
                    title={content.acf.title ?
                        content.acf.title : content.title
                    }
                />
                <Tiles solution={content.link}/>
                {content.content &&
                    <section
                        className="blocks"
                        dangerouslySetInnerHTML={{__html: content.content}}
                    />
                }
            </Layout>
        );
    }
}

export const query = graphql`
    query($id: Int!) {
        wordpressWpSolutions(wordpress_id: {eq: $id}) {
            acf {
                subtitle
                title
            }
            content
            link
            slug
            title
        }
    }
`;
